<template>
    <div id="Medycyna">
        <h1>MEDYCYNA ESTETYCZNA</h1>
        <div id="main-stym" class="flex-container">
            <h2>STYMULATORY TKANKOWE</h2>
            <div id="stymt1">
                <p>
                    W świecie, gdzie codziennie szukamy nowych sposobów na
                    zachowanie młodości i witalności, pragniemy przedstawić Państwu
                    przełom w dziedzinie pielęgnacji skóry – stymulatory tkankowe. To
                    nie tylko produkty, to klucze do drzwi, za którymi czeka zdrowsza,
                    bardziej elastyczna i młodsza skóra.
                    <br>
                    Stymulatory tkankowe wykorzystują zaawansowaną technologię,
                    aby pobudzić naturalne procesy regeneracyjne Twojej skóry. Dzięki
                    precyzyjnie skonstruowanemu działaniu, wyroby te wspierają
                    produkcję kolagenu, elastyny oraz innych kluczowych składników,
                    które są fundamentem zdrowej, promiennej cery.
                    <br>
                    Czy marzysz o skórze wolnej od zmarszczek, nawilżonej i pełnej
                    blasku? Stymulatory tkankowe to Twój nowy sojusznik w walce z
                    upływającym czasem. Regularne ich stosowanie pozwala znacząco
                    poprawić wygląd skóry, redukując oznaki starzenia i przywracając
                    jej naturalny, młodzieńczy blask.
                </p>
                <h3>
                    <strong>Korzyści stosowania naszych stymulatorów tkankowych</strong>:
                </h3>
                <div id="stym-text">
                    <p>
                        • Wzrost produkcji kolagenu i elastyny, co przekłada się na większą elastyczność i jędrność skóry.
                        <br>
                        • Widoczne zmniejszenie głębokości zmarszczek i drobnych linii.
                        <br>
                        • Poprawa ogólnej struktury skóry, co daje efekt odmłodzenia.
                        <br>
                        • Zwiększenie zdolności skóry do zatrzymywania wilgoci, co zapewnia jej głębokie nawilżenie.
                        <br>
                        • Poprawa mikrokrążenia, co przyczynia się do lepszego odżywienia skóry i jej naturalnego blasku.
                    </p>
                </div>
                <p>
                    Niezależnie od tego, czy masz 25, 45, czy 65 lat, stymulatory
                    tkankowe są dla Ciebie. Daj sobie szansę na odmłodzenie i poczuj
                    różnicę – zobacz, jak Twoja skóra staje się bardziej elastyczna,
                    promienna i po prostu piękniejsza.
                    Nie czekaj na jutro, zacznij dzisiaj! Zainwestuj w swoją skórę,
                    zainwestuj w swoje zdrowie i dobre samopoczucie. Już teraz

                    odwiedź naszą stronę internetową lub skontaktuj się z nami, aby
                    dowiedzieć się więcej o tym, jak możemy Ci pomóc osiągnąć
                    wygląd skóry, o którym zawsze marzyłeś.
                </p>
                <h3>
                    Twoja przygoda z młodością zaczyna się teraz!
                </h3>
            </div>
        </div>
        <div id="przerywnikmed1">
            <img src="../assetsPng/przerywnik3.png" width="200" height="40">
        </div>
        <div id="main-mezo" class="flex-container">
            <h2>MEZOTERAPIA</h2>
            <div id="mezot1">
                <h3>Odkryj Moc Mezoterapii – Sekret Młodości i Witalności Skóry</h3>
                <p>
                    W dzisiejszym świecie, gdzie tempo życia i ciągły stres odbijają się
                    na kondycji naszej skóry, mezoterapia wyłania się jako rewelacyjne
                    rozwiązanie, przywracając skórze młodość, blask i zdrowie. Ta
                    metoda, korzystająca z iniekcji, dostarcza składniki odżywcze
                    bezpośrednio w głąb skóry, tam gdzie są najbardziej potrzebne. Oto
                    działanie i zalety mezoterapii, które uczynią ją Twoim nowym
                    sprzymierzeńcem w dbaniu o piękno.
                </p>
                <h3>Działanie Mezoterapii – Precyzja, która Przemawia</h3>
                <p>
                    Mezoterapia polega na wprowadzaniu niewielkich dawek substancji
                    aktywnych bezpośrednio pod skórę, dzięki czemu możliwe jest
                    precyzyjne dotarcie do problematycznych obszarów. Stosowane
                    substancje, takie jak witaminy, minerały, kwas hialuronowy czy
                    peptydy, są dobierane indywidualnie do potrzeb Twojej skóry, co
                    gwarantuje uzyskanie maksymalnych efektów. Metoda ta stymuluje
                    naturalne procesy regeneracyjne w skórze, poprawiając jej
                    elastyczność, nawilżenie oraz ogólną kondycję.
                </p>
                <h3>Zalety Mezoterapii – Ponadczasowe Piękno w Zasięgu Ręki</h3>
                <div id="mezo-text">
                    <p>
                        • <strong>Wszechstronność</strong> – Mezoterapia znajduje zastosowanie
                        nie tylko w rewitalizacji, odmładzaniu i rozjaśnieniu skóry, ale
                        również w walce z cellulitem, rozstępami oraz nadmiernym
                        wypadaniem włosów.
                        <br>
                        • <strong>Natychmiastowe rezultaty</strong> – Pierwsze efekty są
                        widoczne kilka dni po zabiegu, a skóra z każdą kolejną sesją
                        staje się coraz bardziej promienna i jędrna.
                        <br>
                        • <strong>Minimalna inwazyjność</strong> – W porównaniu z innymi
                        metodami odmładzania, mezoterapia jest mniej inwazyjna, co
                        oznacza krótszy czas rekonwalescencji i mniejsze ryzyko
                        powikłań.
                        <br>
                        • <strong>Długotrwałe efekty</strong> – Regularnie przeprowadzane
                        zabiegi pozwalają utrzymać skórę w doskonałej kondycji na
                        dłużej, opóźniając procesy starzenia.
                        <br>
                        • <strong>Personalizacja</strong> – Możliwość dopasowania koktajli
                        odżywczych do indywidualnych potrzeb skóry sprawia, że
                        każdy zabieg jest wyjątkowo efektywny.
                    </p>
                </div>
                <h3>Zainwestuj w Swoją Skórę – Wybierz Mezoterapię</h3>
                <p>
                    Jeśli marzysz o skórze pełnej blasku, bez oznak zmęczenia czy
                    starzenia, mezoterapia jest rozwiązaniem stworzonym dla Ciebie.
                    Pozwól sobie na profesjonalną pielęgnację, która przywróci Twojej
                    skórze młodość i witalność. Wybierz sprawdzoną metodę, zaufaj
                    ekspertom i ciesz się piękną, zdrową skórą każdego dnia.
                </p>
                <h3>Nie czekaj, odkryj sekret pięknej skóry już dziś!</h3>
            </div>
        </div>
        <div id="przerywnikmed2">
            <img src="../assetsPng/przerywnik3.png" width="200" height="40">
        </div>
        <div id="main-botox" class="flex-container">
            <h2>TOKSYNA BOTULINOWA</h2>
            <div id="bott1">
                <h3>
                    Wszechstronna substancja nie tylko do walki ze zmarszczkami
                    Przełom w Odnowie Skóry: Profesjonalne Zastosowanie Toksyny Bot
                </h3>
                <p>
                    W świecie nowoczesnej dermatologii i medycyny estetycznej,
                    substancja ta stała się synonimem odmłodzenia i wyrafinowanej pielęgnacji skóry.
                    Nie jest już tylko sekretem gwiazd i celebrytów;
                    teraz również może być Twoim sojusznikiem w walce z oznakami starzenia.

                    Działanie toksyny – Precyzja w Służbie Piękna
                    Toksyna bot typu A, jest wykorzystywana do czasowego
                    rozluźniania mięśni mimicznych, co prowadzi do wygładzenia
                    istniejących zmarszczek i zapobiegania powstawaniu nowych.
                    Dzięki najnowszej wiedzy i doświadczeniu lekarzy możliwe jest
                    bardzo precyzyjne, bezpieczne podanie preparatu w specyficzne obszary
                    twarzy, uzyskując naturalny efekt rozluźnienia i
                    wygładzenia.
                </p>
                <h3>Zalety Profesjonalnego Zastosowania Toksyny</h3>
                <div id="botox-text">
                    <p>
                        • <strong>Natychmiastowa poprawa wyglądu</strong> – Efekty
                        zastosowania preparatu są widoczne już po kilku dniach od
                        zabiegu, co czyni go idealnym rozwiązaniem dla tych, którzy
                        pragną szybkich rezultatów.
                        <br>
                        • <strong>Minimalne ryzyko i dyskomfort</strong> – Zabiegi są szybkie, a
                        dyskomfort jest minimalny, co pozwala na powrót do
                        codziennych aktywności niemal od razu.
                        <br>
                        • <strong>Profilaktyka</strong> – stosując toksynę bot zapobiegamy
                        utrwalaniu się zmarszczek mimicznych.
                        <br>
                        • <strong>Wysoka personalizacja</strong> – Dzięki możliwości
                        precyzyjnego aplikowania, zabiegi mogą być dostosowane do
                        indywidualnych potrzeb i oczekiwań każdego pacjenta,
                        zapewniając uzyskanie naturalnych efektów.
                        <br>
                        • <strong>Długotrwałe rezultaty</strong> – Efekty mogą utrzymywać się od
                        3 do 6 miesięcy, co sprawia, że toksyna botulinowa jest
                        doskonałym rozwiązaniem dla tych, którzy szukają
                        długotrwałej poprawy wyglądu.
                        <br>
                        • <strong>Wszechstronność</strong> – sam preparat znajduje
                        zastosowanie nie tylko w redukcji zmarszczek, ale również w
                        leczeniu nadmiernego pocenia się, migren czy bruksizmu.
                    </p>
                </div>
                <h3>Inwestycja w Siebie – Wybierz Profesjonalizm</h3>
                <p>
                    Wybierając toksynę bot, decydujesz się na inwestycję w
                    swoją pewność siebie i dobre samopoczucie. W naszej klinice,
                    zespół doświadczonych lekarzy gwarantuje, że zabieg będzie
                    bezpieczny, komfortowy i dostosowany do Twoich unikalnych
                    potrzeb. Zaufaj nam, a my pomożemy Ci osiągnąć wygląd, którego
                    zawsze pragnąłeś/aś, zachowując naturalność i promienność.
                </p>
                <h3>
                    Zarezerwuj konsultację już dziś i zrób pierwszy krok ku
                    doskonałości.
                </h3>
            </div>
        </div>
        <div id="przerywnikmed3">
            <img src="../assetsPng/przerywnik3.png" width="200" height="40">
        </div>
        <div id="main-kwas" class="flex-container">
            <h2>KWAS HIALURONOWY</h2>
            <div id="kwast1">
                <h3>Odmień Swoją Skórę z Cudownym Kwasem Hialuronowym</h3>
                <p>
                    W erze poszukiwania wiecznej młodości i piękna, kwas hialuronowy
                    wyłania się jako złoty standard w pielęgnacji skóry. Ten niezwykły
                    składnik, chwalony za swoje intensywne właściwości nawilżające i
                    odmładzające, jest kluczem do odzyskania promiennej, jędrnej i
                    młodzieńczej skóry. Poznaj działanie i zalety, które sprawiają, że
                    kwas hialuronowy jest „must-have” każdej profesjonalnej
                    pielęgnacji.
                </p>
                <h3>
                    Działanie Kwasu Hialuronowego – Nawilżenie i Regeneracja na
                    Najwyższym Poziomie
                </h3>
                <p>
                    Kwas hialuronowy to substancja naturalnie występująca w ludzkim
                    ciele, znana z umiejętności zatrzymywania wody w skórze, co
                    zapewnia jej elastyczność i jędrność. Jego unikalna zdolność do
                    wiązania 1000-krotności swojej masy w wodzie sprawia, że jest
                    niezastąpionym sojusznikiem w walce z oznakami starzenia.
                    Profesjonalne zabiegi z użyciem kwasu hialuronowego mogą w
                    znacznym stopniu poprawić nawilżenie skóry, zredukować drobne
                    linie i zmarszczki oraz przywrócić skórze jej naturalny blask.
                </p>
                <h3>Zalety Zastosowania Kwasu Hialuronowego</h3>
                <div id="kwas-text">
                    <p>
                        • <strong>Głębokie Nawilżenie</strong> – Kwas hialuronowy wnika głęboko
                        w skórę, zapewniając długotrwałe nawilżenie i przywracając jej
                        miękkość oraz gładkość.
                        <br>
                        • <strong>Widoczne Odmłodzenie</strong> – Poprzez wygładzenie
                        drobnych linii i zmarszczek, kwas hialuronowy pomaga
                        odzyskać młodzieńczy wygląd skóry, czyniąc ją bardziej
                        promienną.
                        <br>
                        • <strong>Regeneracja i Ochrona</strong> – Pomaga w regeneracji
                        naskórka i chroni skórę przed szkodliwymi czynnikami
                        zewnętrznymi, takimi jak zanieczyszczenie czy ekstremalne
                        temperatury.
                        <br>
                        • <strong>Bezpieczeństwo i Komfort</strong> – Zabiegi z użyciem kwasu
                        hialuronowego są bezpieczne, praktycznie bezbolesne i nie
                        wymagają czasu na rekonwalescencję, co pozwala na szybki
                        powrót do codziennych aktywności.
                        <br>
                        • <strong>Wszechstronność</strong> – Kwas hialuronowy jest skuteczny
                        dla każdego typu skóry, od młodej i mieszanej po dojrzałą i
                        suchą.
                    </p>
                </div>
                <p>
                    Nie pozwól, aby czas odbierał Twojej skórze jej naturalne piękno.
                    Zainwestuj w zabiegi z kwasem hialuronowym i obserwuj, jak Twoja
                    skóra odzyskuje blask, staje się gładsza, pełna życia i widocznie
                    młodsza. W naszej Klinice, doświadczeni lekarze pomogą dobrać
                    odpowiedni zabieg, dostosowany do indywidualnych potrzeb Twojej
                    skóry.
                </p>
                <h3>
                    Nie czekaj, odmień swoją skórę już dziś. Zarezerwuj wizytę i
                    poczuj różnicę, jaką może przynieść kwas hialuronowy.
                </h3>
            </div>
        </div>
        <div id="przerywnikmed4">
            <img src="../assetsPng/przerywnik3.png" width="200" height="40">
        </div>
        <div id="main-wolu" class="flex-container">
            <h2>WOLUMETRIA</h2>
            <div id="wolut1">
                <h3>
                    Rewolucja w Odbudowie Owalu Twarzy: Kwas Hialuronowy w
                    Wolumetrii Twarzy
                </h3>
                <p>
                    Procesy starzenia w obrębie naszej twarzy dotyczą zarówno skóry,
                    jak i głębszych warstw tkankowych – poduszeczek tłuszczowych
                    czy kości. W dziedzinie medycyny estetycznej, wolumetria twarzy
                    przy użyciu kwasu hialuronowego jest niczym prawdziwa rewolucja,
                    otwierająca nowe możliwości w odmładzaniu i modelowaniu twarzy.
                    Dzięki temu zaawansowanemu zabiegowi, możesz nie tylko
                    zniwelować oznaki starzenia, ale również przywrócić twarzy jej
                    naturalne, młodzieńcze kontury.. Oto jak kwas hialuronowy może
                    odmienić Twoją twarz, zapewniając spektakularne efekty bez

                    konieczności poddawania się inwazyjnym procedurom
                    chirurgicznym.
                </p>
                <h3>Kwas Hialuronowy: Sekret Młodości w Twoich Rękach</h3>
                <p>
                    Kwas hialuronowy, dzięki swoim unikalnym właściwościom
                    nawilżającym i zdolności do wiązania wody, jest idealnym środkiem
                    do przywracania objętości tam, gdzie jest to najbardziej potrzebne.
                    Wolumetria twarzy, wykorzystując zaawansowane formuły kwasu
                    hialuronowego, pozwala na precyzyjne modelowanie twarzy,
                    wypełnienie utraconej objętości oraz wygładzenie zmarszczek i linii
                    mimicznych, oferując prowadząc do natychmiastowych i naturalnie
                    wyglądających rezultatów.
                </p>
                <h3>
                    Zalety Zastosowania Kwasu Hialuronowego w Wolumetrii
                    Twarzy
                </h3>
                <div id="wolu-text">
                    <p>
                        • <strong>Przywracanie Objętości</strong> – Kwas hialuronowy efektywnie
                        przywraca objętość w obszarach, takich jak policzki, broda czy
                        linia żuchwy, co przyczynia się do odmłodzenia i ożywienia
                        całej twarzy.
                        <br>
                        • <strong>Redukcja Zmarszczek</strong> – Oprócz wypełniania utraconej
                        objętości, kwas hialuronowy wygładza również zmarszczki i
                        linie mimiczne, zapewniając bardziej młodzieńczy wygląd.
                        <br>
                        • <strong>Poprawa Konturów Twarzy</strong> – Dzięki możliwości
                        precyzyjnego modelowania, zabieg pozwala na poprawę i
                        definiowanie konturów twarzy, co sprawia, że staje się ona
                        bardziej symetryczna i proporcjonalna.
                        <br>
                        • <strong>Bezpieczeństwo i Komfort</strong> – Zabiegi z użyciem kwasu
                        hialuronowego są bezpieczne, minimalnie inwazyjne i
                        zazwyczaj nie wymagają długiego okresu rekonwalescencji, co
                        oznacza, że możesz szybko wrócić do swoich codziennych
                        zajęć.
                        <br>
                        • <strong>Natychmiastowe i Długotrwałe Efekty</strong> – Efekty są
                        widoczne niemal natychmiast po zabiegu i mogą utrzymywać
                        się od kilku do nawet 18 miesięcy, w zależności od typu
                        zastosowanego produktu i indywidualnych cech organizmu.
                    </p>
                </div>
                <h3>Inwestycja w Piękno, która Się Opłaca</h3>
                <p>
                    Wybierając wolumetrię twarzy kwasem hialuronowym, inwestujesz
                    w swoje piękno, pewność siebie i dobre samopoczucie. W naszej
                    Klinice, doświadczeni lekarze zadbają o to, by zabieg był
                    dopasowany do Twoich indywidualnych potrzeb i oczekiwań,
                    gwarantując satysfakcję z osiągniętych rezultatów.
                </p>
                <h3>
                    Zarezerwuj swoją konsultację już dziś i zobacz, jak kwas
                    hialuronowy w wolumetrii twarzy może Cię odmienić. Odkryj
                    swoje nowe, młodsze JA!
                </h3>
            </div>
        </div>
        <div id="przerywnikmed5">
            <img src="../assetsPng/przerywnik3.png" width="200" height="40">
        </div>
        <div id="main-osocze" class="flex-container">
            <h2>OSOCZE BOGATOPŁYTKOWE</h2>
            <div id="osoczet1">
                <h3>
                    Odkryj Moc Regeneracji: Zabiegi z Użyciem Osocza
                    Bogatopłytkowego
                </h3>
                <p>
                    W świecie nowoczesnej medycyny estetycznej, zabiegi z użyciem
                    osocza bogatopłytkowego (PRP) rewolucjonizują podejście do
                    odmładzania i regeneracji skóry. Ta innowacyjna metoda,
                    wykorzystująca uzdrawiającą moc własnej krwi pacjenta, otwiera
                    drzwi do naturalnej, bezpiecznej i efektywnej odnowy. Zapoznaj się
                    z niezwykłymi zaletami tej procedury, dzięki której możesz cieszyć
                    się młodszą, zdrowszą i bardziej promienną skórą.
                </p>
                <h3>Zalety Zabiegów z Osoczem Bogatopłytkowym</h3>
                <div id="osocze-text">
                    <p>
                        • <strong>Naturalna Metoda Regeneracji</strong> – Wykorzystując własne
                        osocze pacjenta, zabieg jest w pełni naturalny i minimalizuje
                        ryzyko reakcji alergicznych lub nietolerancji.
                        <br>
                        • <strong>Wszechstronność Zastosowań</strong> – PRP doskonale
                        sprawdza się w odmładzaniu skóry twarzy, szyi, dekoltu, a
                        także w terapii przeciw wypadaniu włosów i leczeniu blizn.
                        <br>
                        • <strong>Stymulacja Produkcji Kolagenu</strong> – Czynniki wzrostu
                        zawarte w płytkach krwi aktywizują naturalne procesy
                        regeneracyjne, co przekłada się na zwiększenie produkcji
                        kolagenu i elastyny, zapewniając skórze jędrność i
                        elastyczność.
                        <br>
                        • <strong>Krótki Okres Rekonwalescencji</strong> – Zabieg jest
                        minimalnie inwazyjny, co oznacza, że możesz szybko wrócić
                        do swoich codziennych aktywności.
                        <br>
                        • <strong>Długotrwałe i Naturalnie Wyglądające Efekty</strong> –
                        Rezultaty są stopniowe, ale długotrwałe, co sprawia, że skóra
                        wygląda młodziej i zdrowiej w naturalny sposób.
                    </p>
                </div>
                <h3>
                    Zainwestuj w Swoją Skórę, Wybierając Zabiegi z Użyciem
                    Osocza Bogatopłytkowego
                </h3>
                <p>
                    Jeśli marzysz o odmłodzonej, zdrowej i pełnej blasku skórze bez
                    konieczności poddawania się inwazyjnym zabiegom, zabiegi z
                    osoczem bogatopłytkowym mogą być idealnym rozwiązaniem. W
                    naszej Klinice, doświadczeni lekarze zapewnią Ci kompleksową
                    opiekę i dostosują procedurę do Twoich indywidualnych potrzeb,
                    aby osiągnąć najlepsze możliwe efekty.
                </p>
                <h3>
                    Nie czekaj, odkryj sekret młodej i zdrowej skóry dzięki
                    zabiegom z osoczem bogatopłytkowym. Zarezerwuj swoją
                    konsultację już dziś!
                </h3>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    }
}
</script>

<style lang="scss" scoped>
@import '../styles/style.scss';
    *
    {
        h1{
            color: #BD924A;
            cursor: pointer;
            text-align: center;
        }
        h2{
            color: #BD924A;
            cursor: pointer;
        }
        text-decoration: none;
        h3{
            color: #BD924A;
            text-align: center;
        }
    }
    #Medycyna{
        display: flex;
        flex-direction: column;
        text-align: center;

        #main-stym{
            display: flex;
            flex-direction: column;
            align-items: center;
            #stymt1{
                text-align: justify;
            }
        }
        #main-mezo{
            display: flex;
            flex-direction: column;
            align-items: center;
            #mezot1{
                text-align: justify;
            }
        }
        #main-botox{
            display: flex;
            flex-direction: column;
            align-items: center;
            #bott1{
                text-align: justify;
            }
        }
        #main-kwas{
            display: flex;
            flex-direction: column;
            align-items: center;
            #kwast1{
                text-align: justify;
            }
        }
        #main-wolu{
            display: flex;
            flex-direction: column;
            align-items: center;
            #wolut1{
                text-align: justify;
            }
        }
        #main-osocze{
            display: flex;
            flex-direction: column;
            align-items: center;
            #osoczet1{
                text-align: justify;
            }
        }
        #przerywnikmed0{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        #przerywnikmed1{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        #przerywnikmed2{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        #przerywnikmed3{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        #przerywnikmed4{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        #przerywnikmed5{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
</style>